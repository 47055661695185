import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toastSuccess, toastError } from 'utils/toasts';
import Card from 'components/card';
import { get, getUserEmail, getUserId, post } from 'api';
import moment from 'moment';
import { CreditsContext } from 'contexts/CreditsContext';

const plansKeyPoints = {
  freelancer: [
    'Sell on your own terms',
    'Website, marketing tools & automations',
    'Bandwidth & storage is included',
    'We’ll get you onboarded',
  ],
  company: [
    'Live chat & countdowns',
    'Website, marketing tools & automations',
    'Bandwidth & storage is included',
    'We’ll get you onboarded',
  ],
  enterprise: [
    'We’ll migrate you for free',
    'Live chat & countdowns',
    'Bandwidth & storage is included',
    'We’ll get you onboarded',
  ],
};

function Pricing() {
  const navigate = useNavigate();
  const { refreshBalance } = useContext(CreditsContext);
  const [activeButton, setActiveButton] = useState('month');
  const [plans, setPlans] = useState([]);
  const [userPlan, setUserPlan] = useState({});
  const userId = getUserId();
  const userEmail = getUserEmail();

  const fetchUserSubscription = async () => {
    const response = await get(`/api/subscriptions/user-plan/${userId}`);
    const data = await response.json();
    setUserPlan(data[0]);
  };

  const fetchPlans = async () => {
    const response = await get('/api/subscriptions/plans');
    const data = await response.json();
    setPlans(data);
  };

  useEffect(() => {
    fetchPlans();
    fetchUserSubscription();
  }, [userId]);

  const fetchSessionId = async () => {
    const res = await get(`/api/subscriptions/session-id/${userId}`);
    const data = await res.json();
    return data[0].sessionId;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get('subscription_status');

    const fetchAndHandlePayment = async () => {
      if (status === 'success') {
        const sessionId = await fetchSessionId();
        handlePaymentSuccess(sessionId);
      }
    };

    fetchAndHandlePayment();

    if (status === 'cancel') {
      toastError('Subscription cancelled');
      setTimeout(() => {
        navigate('/admin/account/credits');
      }, 500);
    }
  }, [userId]);

  const handlePaymentSuccess = async (sessionId) => {
    const body = {
      userId: userId,
      sessionId: sessionId,
    };
    await post('/api/subscriptions/payment-success', body)
      .then((res) => {
        if (res.ok) return res.json();
        return res.json().then((json) => Promise.reject(json));
      })
      .then((data) => {
        toastSuccess(data.msg);
        fetchUserSubscription();
        refreshBalance();
        navigate('/admin/account/credits');
      })
      .catch(() => {
        toastError('Subscription failed');
        navigate('/admin/account/credits');
      });
  };

  function getFPTid() {
    return window.FPROM && window.FPROM.data.tid;
  }

  const handlePlanSelection = async (plan) => {
    if (plan.subscriptionName === 'Enterprise') {
      window.location.href = 'mailto:info@linkpricer.com';
    }
    try {
      const body = {
        userId: userId,
        userEmail: userEmail,
        subscriptionType: activeButton,
        subscriptionName: plan.subscriptionName,
        fp_tid: getFPTid(),
      };
      await post(
        '/api/subscriptions/create-subscription-checkout-session',
        body,
      )
        .then((res) => {
          if (res.ok) return res.json();
          return res.json().then((json) => Promise.reject(json));
        })
        .then(({ session }) => {
          window.location = session.url;
        });
    } catch (error) {
      toastError('Subscription failed');
    }
  };

  const isSubscribedPlan = (plan) => {
    if (!userPlan) return false;
    return (
      userPlan.planType === plan.subscriptionName &&
      userPlan.planInterval === activeButton &&
      userPlan.planEndDate >= moment().format('YYYY-MM-DD')
    );
  };

  return (
    <div className="relative h-full w-full px-3 font-dm dark:bg-navy-900">
      <div className="-z-1 absolute left-0 right-0 max-h-[60vh] min-h-[60vh] w-full overflow-hidden rounded-[20px] bg-gradient-to-br from-brand-400 to-brand-600 bg-cover bg-no-repeat md:mx-auto" />
      <div className="z-1 relative">
        {/* Header content */}
        <div className="mx-auto flex w-full max-w-screen-sm flex-col items-center justify-center text-center md:px-3">
          <div className="mt-8 flex h-[50px] w-[280px] items-center rounded-full bg-navy-800 p-1.5">
            <button
              className={`linear flex h-full w-1/2 cursor-pointer select-none items-center justify-center rounded-[20px] text-xs font-bold uppercase text-brand-500 transition duration-200 ${
                activeButton === 'month'
                  ? 'bg-white text-brand-500'
                  : 'bg-transparent text-white'
              } `}
              onClick={() => setActiveButton('month')}
            >
              Monthly
            </button>
            <button
              className={`linear flex h-full w-1/2 cursor-pointer select-none items-center justify-center rounded-[20px] text-xs font-bold uppercase text-brand-500 transition duration-200 ${
                activeButton === 'year'
                  ? 'bg-white text-navy-700'
                  : 'bg-transparent text-white'
              }`}
              onClick={() => setActiveButton('year')}
            >
              Yearly
            </button>
          </div>
        </div>
      </div>
      {/* Pricing section */}
      <div className="relative mx-auto mb-20 mt-12 grid h-fit w-full max-w-[375px] grid-cols-1 gap-3 md:mb-[160px] md:px-3 xl:mt-24 xl:max-w-full xl:grid-cols-3 2xl:max-w-max">
        {plans.map((plan) => (
          <Card
            key={plan.id}
            extra={`${
              isSubscribedPlan(plan)
                ? 'w-full h-fit rounded-[20px] !pb-10 mt-2 xl:!-mt-8 pt-8 px-[20px]'
                : 'w-full h-full rounded-[20px] pb-6 pt-8 px-[20px]'
            }`}
          >
            {isSubscribedPlan(plan) && (
              <div className="-mt-[45px] mb-[38px] flex w-full justify-center">
                <div className="rounded-[20px] bg-[#FFF6DA] px-3 py-[6px] text-center text-sm  font-bold text-yellow-500">
                  Active Subscription
                </div>
              </div>
            )}
            <div className={isSubscribedPlan(plan) ? 'mt-2' : ''}>
              <h5 className="font-dm text-3xl font-bold text-navy-700 dark:text-white">
                {plan.subscriptionName}
              </h5>
              <p className="mt-1 font-dm text-base font-medium text-gray-600">
                {plan.description}
              </p>
              <button
                className={
                  isSubscribedPlan(plan)
                    ? 'linear mt-[30px] w-full rounded-xl bg-green-500 py-2 font-dm text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700'
                    : 'linear mt-[30px] w-full rounded-xl bg-brand-500 py-2 font-dm text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700'
                }
                onClick={() => handlePlanSelection(plan)}
                disabled={isSubscribedPlan(plan)}
              >
                {isSubscribedPlan(plan) ? 'Subscribed' : 'Get Started'}
              </button>
              <div className="mt-8 w-full">
                <h5 className="font-dm text-4xl font-bold text-navy-700 dark:text-white">
                  {plan.subscriptionName === 'Enterprise'
                    ? 'Contact us'
                    : activeButton === 'month'
                    ? `${Number(plan.monthlyPrice).toLocaleString()} €`
                    : `${Number(plan.yearlyPrice).toLocaleString()} €`}
                  {plan.subscriptionName !== 'Enterprise' && (
                    <span className="text-gray-600">
                      {' '}
                      / {activeButton === 'month' ? 'month' : 'year'}
                    </span>
                  )}
                </h5>
                <p className="mt-[8px] text-base font-medium text-gray-600">
                  {plan.subscriptionName === 'Enterprise'
                    ? 'Individual pricing available on request'
                    : activeButton === 'month'
                    ? `(${Number(
                        plan.monthlyCredits,
                      ).toLocaleString()} credits per month)`
                    : `(${Number(
                        plan.yearlyCredits,
                      ).toLocaleString()} credits per year)`}
                </p>
              </div>
              <div className="mt-6">
                {plansKeyPoints[plan.subscriptionName.toLowerCase()].map(
                  (keyPoint, index) => (
                    <div key={index} className="mb-4 flex items-center gap-2">
                      <div className="flex h-2.5 w-2.5 items-end justify-center rounded-full bg-navy-700 dark:bg-white" />
                      <h5 className="text-base font-medium text-navy-700 dark:text-white">
                        {keyPoint}
                      </h5>
                    </div>
                  ),
                )}
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default Pricing;
